import {
  edsn_Marktrol,
  RegistrationChangeDto,
} from "../../../openapi/edsn-webapi";
import { YesNoControl } from "../../common";
import { safePropertyMap } from "../../../utils";
import { useFormContext } from "react-hook-form";
import { Alert, Box } from "@mui/material";
import CSPDetails from "../CSPDetails";
import ProductSelectControl from "../ProductSelectControl";
import RecognitionDetails from "../RecognitionDetails";
import OrganizationUnits from "../OrganizationUnits";
import Ean13Control from "../Ean13Control";
import { FormattedMessage } from "react-intl";
import PreferredPartyElectricityControl from "../PreferredPartyElectricityControl";
import PreferredPartyGasControl from "../PreferredPartyGasControl";
import useRegistration from "../useRegistration";
import { useEffect } from "react";
import BSPDetails from "../BSPDetails";

const RegistrationChangeStep = () => {
  const map = safePropertyMap<RegistrationChangeDto>();
  const { watch } = useFormContext<RegistrationChangeDto>();
  const showCompanyDetails =
    (watch("companyDetailsChanged") as any) === "*true*";
  const showOrganistaionDetails =
    (watch("organizationUnitsChanged") as any) === "*true*";
  const marketRole = watch("marketRole")!;
  const addressUpdated = (watch("addressUpdated") as any) === "*true*";
  const showPreferredPartyElec =
    (watch("preferredPartyElectricityContractChanged") as any) === "*true*";
  const showPreferredPartyGas =
    (watch("preferredPartyGasContractChanged") as any) === "*true*";

  const showProduct = [
    edsn_Marktrol.LVLEVERANCIER,
    edsn_Marktrol.PVPROGRAMMAVERANTWOORDELIJKE,
    edsn_Marktrol.MVMEETVERANTWOORDELIJKE,
    edsn_Marktrol.GDSEIGENAAR_GESLOTEN_DISTRIBUTIE_SYSTEEM,
  ].includes(marketRole);

  const showAdminParty = [
    edsn_Marktrol.LVLEVERANCIER,
    edsn_Marktrol.PVPROGRAMMAVERANTWOORDELIJKE,
  ].includes(marketRole);

  const showOrganisationDetailsMartket = [
    edsn_Marktrol.LVLEVERANCIER,
    edsn_Marktrol.PVPROGRAMMAVERANTWOORDELIJKE,
    edsn_Marktrol.MVMEETVERANTWOORDELIJKE,
    edsn_Marktrol.GDSEIGENAAR_GESLOTEN_DISTRIBUTIE_SYSTEEM,
    edsn_Marktrol.CSPCONGESTIE_SERVICE_PROVIDER,
    edsn_Marktrol.BSPBALANCING_SERVICE_PROVIDER,
  ].includes(marketRole);

  const showLvTooltip = marketRole === edsn_Marktrol.LVLEVERANCIER;

  const setPermissionDocumentGas = useRegistration(
    (state) => state.setPermissionDocumentGas
  );
  const setPermissionDocumentelectricity = useRegistration(
    (state) => state.setPermissionDocumentelectricity
  );

  useEffect(() => {
    if (!showPreferredPartyGas) {
      setPermissionDocumentGas(undefined);
    }
  }, [showPreferredPartyGas, setPermissionDocumentGas]);

  useEffect(() => {
    if (!showPreferredPartyElec) {
      setPermissionDocumentelectricity(undefined);
    }
  }, [showPreferredPartyElec, setPermissionDocumentelectricity]);

  return (
    <Box>
      <Ean13Control />
      {showOrganisationDetailsMartket && (
        <YesNoControl
          name={map("companyDetailsChanged")}
          defaultValue={false}
        />
      )}
      {showCompanyDetails && (
        <>
          <Alert severity="info" sx={{ mb: 1 }}>
            <FormattedMessage id="companyDetailsChangeInfo" />
          </Alert>
          {showProduct && <ProductSelectControl />}
          <CSPDetails />
          <BSPDetails />
          <RecognitionDetails />
        </>
      )}

      {showAdminParty && (
        <>
          <YesNoControl
            name={map("preferredPartyElectricityContractChanged")}
            onChange={(e) => console.log(e)}
            defaultValue={false}
          />
          {showPreferredPartyElec && (
            <PreferredPartyElectricityControl
              showLvTooltip={showLvTooltip}
              required
            />
          )}
          <YesNoControl
            name={map("preferredPartyGasContractChanged")}
            defaultValue={false}
          />
          {showPreferredPartyGas && (
            <PreferredPartyGasControl showLvTooltip={showLvTooltip} required />
          )}
          <YesNoControl
            name={map("organizationUnitsChanged")}
            defaultValue={false}
          />
          {showOrganistaionDetails && <OrganizationUnits />}
        </>
      )}
      <YesNoControl name={map("addressUpdated")} defaultValue={false} />
      {addressUpdated && (
        <Alert severity="info">
          <FormattedMessage id="addressUpdatedInfo" />
        </Alert>
      )}
    </Box>
  );
};

export default RegistrationChangeStep;
