/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PreferredPartyEanValidationDto } from '../models/PreferredPartyEanValidationDto';
import type { PreferredPartyEanValidationResponsDto } from '../models/PreferredPartyEanValidationResponsDto';
import type { RegistrationCancelDto } from '../models/RegistrationCancelDto';
import type { RegistrationChangeDto } from '../models/RegistrationChangeDto';
import type { RegistrationCreateDto } from '../models/RegistrationCreateDto';
import type { RegistrationResponseDto } from '../models/RegistrationResponseDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MprService {

    /**
     * @returns PreferredPartyEanValidationResponsDto OK
     * @throws ApiError
     */
    public static postApiMprValidatePreferredParty({
        requestBody,
    }: {
        requestBody?: PreferredPartyEanValidationDto,
    }): CancelablePromise<PreferredPartyEanValidationResponsDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Mpr/validate-preferred-party',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static postApiMprUpload({
        formData,
    }: {
        formData?: {
            RegistrationId?: string;
            Files?: Array<Blob>;
        },
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Mpr/upload',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public static postApiMprCancel({
        requestBody,
    }: {
        requestBody?: RegistrationCancelDto,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Mpr/cancel',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @returns RegistrationResponseDto OK
     * @throws ApiError
     */
    public static postApiMprChange({
        requestBody,
    }: {
        requestBody?: RegistrationChangeDto,
    }): CancelablePromise<RegistrationResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Mpr/change',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @returns RegistrationResponseDto OK
     * @throws ApiError
     */
    public static postApiMprCreate({
        requestBody,
    }: {
        requestBody?: RegistrationCreateDto,
    }): CancelablePromise<RegistrationResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Mpr/create',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @returns RegistrationResponseDto OK
     * @throws ApiError
     */
    public static putApiMprCreate({
        id,
        token,
        requestBody,
    }: {
        id: string,
        token: string,
        requestBody?: RegistrationCreateDto,
    }): CancelablePromise<RegistrationResponseDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/Mpr/create/{id}/{token}',
            path: {
                'id': id,
                'token': token,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @returns RegistrationCreateDto OK
     * @throws ApiError
     */
    public static getApiMprCreate({
        id,
        token,
    }: {
        id: string,
        token: string,
    }): CancelablePromise<RegistrationCreateDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Mpr/create/{id}/{token}',
            path: {
                'id': id,
                'token': token,
            },
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @returns RegistrationResponseDto OK
     * @throws ApiError
     */
    public static putApiMprChange({
        id,
        token,
        requestBody,
    }: {
        id: string,
        token: string,
        requestBody?: RegistrationChangeDto,
    }): CancelablePromise<RegistrationResponseDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/Mpr/change/{id}/{token}',
            path: {
                'id': id,
                'token': token,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @returns RegistrationChangeDto OK
     * @throws ApiError
     */
    public static getApiMprChange({
        id,
        token,
    }: {
        id: string,
        token: string,
    }): CancelablePromise<RegistrationChangeDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Mpr/change/{id}/{token}',
            path: {
                'id': id,
                'token': token,
            },
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

}
