import { Card, CardContent, CardHeader, Grid, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  OrganizationUnitDto,
  RegistrationCreateDto,
} from "../../../openapi/edsn-webapi";
import { useState } from "react";
import OrganizationUnitModal from "./OrganizationUnitModal";
import { useFormContext } from "react-hook-form";
import { FormattedDate, FormattedMessage } from "react-intl";
import OrganizationUnitCardRow from "./OrganizationUnitCardRow";

type OrganizationUnitCardProps = {
  index: number;
  isCreate?: boolean;
};

const OrganizationUnitCard = ({
  index,
  isCreate,
  ...organizationUnit
}: OrganizationUnitDto & OrganizationUnitCardProps) => {
  const [open, setOpen] = useState(false);
  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);
  const { setValue, getValues } = useFormContext<RegistrationCreateDto>();

  const updateOrganizationUnit = (data: OrganizationUnitDto) => {
    setValue(`organizationUnits.${index}`, data);
    closeModal();
  };

  const deleteOraganizationUnit = () => {
    setValue(
      "organizationUnits",
      getValues("organizationUnits")!.filter((_, i) => i !== index)
    );
  };

  return (
    <>
      <Card sx={{ position: "relative", minHeight: 184 }}>
        <CardHeader
          title={
            !isCreate ? (
              <FormattedMessage
                id={`edsn_organizationalunit_edsn_wijzigingtype.${organizationUnit.changeType}`}
              />
            ) : undefined
          }
          action={
            <>
              <IconButton onClick={openModal} aria-label="edit">
                <EditIcon />
              </IconButton>
              <IconButton
                disabled={index === 0 && isCreate}
                onClick={deleteOraganizationUnit}
                aria-label="delete"
              >
                <DeleteIcon />
              </IconButton>
            </>
          }
        />
        <CardContent>
          <Grid container direction="column">
            <OrganizationUnitCardRow
              label="nameMarket"
              value={organizationUnit.name}
            />
            <OrganizationUnitCardRow
              label="eanCodeMarket"
              value={organizationUnit.eaN13}
            />
            <OrganizationUnitCardRow
              label="vatNumb"
              value={organizationUnit.vatNumber}
            />
            <OrganizationUnitCardRow
              label="accountNumb"
              value={organizationUnit.bankAccount}
            />
            <OrganizationUnitCardRow
              label="endDate"
              value={
                organizationUnit.endDate ? (
                  <FormattedDate value={organizationUnit.endDate as string} />
                ) : null
              }
            />
          </Grid>
        </CardContent>
      </Card>
      <OrganizationUnitModal
        open={open}
        data={organizationUnit}
        eanDisabled={index === 0 && !!isCreate}
        onClose={closeModal}
        onSubmit={updateOrganizationUnit}
      />
    </>
  );
};

export default OrganizationUnitCard;
