import { Button, Grid } from "@mui/material";
import { FormattedMessage } from "react-intl";
import OrganizationUnitModal from "./OrganizationUnitModal";
import { useState } from "react";
import {
  edsn_organizationalunit_edsn_wijzigingtype,
  OrganizationUnitDto,
  RegistrationCreateDto,
} from "../../../openapi/edsn-webapi";
import { useFormContext } from "react-hook-form";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

type OrganizationButtonGridProps = {
  isCreate?: boolean;
};

const OrganizationButtonGrid = ({ isCreate }: OrganizationButtonGridProps) => {
  const [changeType, setChangeType] =
    useState<edsn_organizationalunit_edsn_wijzigingtype>();
  const openModal = (type: edsn_organizationalunit_edsn_wijzigingtype) => () =>
    setChangeType(type);
  const closeModal = () => setChangeType(undefined);
  const { setValue, watch, getValues } =
    useFormContext<RegistrationCreateDto>();
  const organizationUnits = watch("organizationUnits") ?? [];

  const addOrganizationUnit = (data: OrganizationUnitDto) => {
    setValue(`organizationUnits.${organizationUnits.length}`, data);
    closeModal();
  };

  return (
    <>
      <Grid container spacing={1} sx={{ mb: 2 }}>
        <Grid item>
          <Button
            onClick={openModal(
              edsn_organizationalunit_edsn_wijzigingtype.TOEVOEGEN
            )}
            variant="contained"
          >
            <AddIcon />
            <FormattedMessage id="organizationUnitCreate" />
          </Button>
        </Grid>
        {!isCreate && (
          <>
            <Grid item>
              <Button
                onClick={openModal(
                  edsn_organizationalunit_edsn_wijzigingtype.WIJZIGEN
                )}
                variant="contained"
              >
                <EditIcon />
                <FormattedMessage id="oganizationUnitUpdate" />
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={openModal(
                  edsn_organizationalunit_edsn_wijzigingtype.VERWIJDEREN
                )}
                variant="contained"
              >
                <DeleteIcon />
                <FormattedMessage id="organizationUnitDelete" />
              </Button>
            </Grid>
          </>
        )}
      </Grid>
      <OrganizationUnitModal
        open={!!changeType}
        data={
          {
            changeType,
            eaN13:
              !organizationUnits?.length && isCreate
                ? getValues("organisatieEan13Code")
                : undefined,
          } as OrganizationUnitDto
        }
        eanDisabled={!organizationUnits?.length && !!isCreate}
        onClose={closeModal}
        onSubmit={addOrganizationUnit}
      />
    </>
  );
};

export default OrganizationButtonGrid;
